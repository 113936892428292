export default {
  init() {
    this.show_more_text();
    this.toggle_authors();
    this.handle_show_more_btn();
  },

  handle_show_more_btn() {
    const BOXES = document.querySelectorAll('.iko-authors__biography');
    BOXES.forEach(BOX => {
      const STYLES = getComputedStyle(BOX);
      const CONTENT_HEIGHT = BOX.offsetHeight;
      const LINE_HEIGHT = parseInt(STYLES['line-height']);
      const NUMBER_OF_LINES = Math.ceil(CONTENT_HEIGHT / LINE_HEIGHT);

      const SHOW_MORE_BTN = BOX.parentElement.parentElement.lastElementChild;

      if (NUMBER_OF_LINES < 7) {
        SHOW_MORE_BTN.style.display = 'none';
      } else {
        SHOW_MORE_BTN.style.display = 'block';
      }
    });

  },

  show_more_text() {
    const READ_MORE_BTN = document.querySelectorAll('.iko-authors__read-more');

    READ_MORE_BTN.forEach(btn => {
      btn.addEventListener('click', e => {
        const link = e.target;
        const textContainer = e.target.previousElementSibling;

        if (textContainer.classList.contains('iko-authors__biography--truncated')) {
          link.textContent = link.getAttribute('data-text-less');

          // textContainer.style.maxHeight = '100%';
          textContainer.classList.remove('iko-authors__biography--truncated');

        } else {
          link.textContent = link.getAttribute('data-text-more');
          textContainer.classList.add('iko-authors__biography--truncated');
        }
      });
    });
  },

  toggle_authors() {
    const togglers = document.querySelectorAll('.iko-btn--selector');

    togglers.forEach(toggler => {
      toggler.addEventListener('click', e => {
        const activeItem = document.querySelector('.iko-authors__columns.active');
        const parent = activeItem.parentNode;
        const direction = e.target.classList.contains('iko-btn--selector--down') || e.target.classList.contains('la-long-arrow-alt-down') ? 'down' : 'up';
        const nextItem = find_next_item(activeItem, parent, direction);

        activeItem.classList.remove('active');
        activeItem.classList.add('inactive')
        nextItem.classList.remove('inactive');
        nextItem.classList.add('active');
        this.handle_show_more_btn();

        function find_next_item(activeItem, parent, direction) {
          if (activeItem == parent.firstElementChild && direction == 'up') {
            return parent.lastElementChild;
          } else if (activeItem == parent.lastElementChild && direction == 'down') {
            return parent.firstElementChild;
          } else {
            return direction == 'down' ? activeItem.nextElementSibling : activeItem.previousElementSibling;
          }
        }

      });
    });
  }
}